import React, { createContext, useContext, useEffect, useState } from "react"
import { onAuthStateChanged } from "firebase/auth"
import { auth, db } from "./firebase"
import { doc, setDoc, getDoc } from "firebase/firestore"
import { analytics } from "./firebase"
import { logEvent } from "firebase/analytics"

const defaultContext = {
  user: null,
  loading: true,
  theme: {},
  suggestions: [
    "My partner does not want to get into the shower. What can I do?",
    "I don’t get any help with household tasks. How to deal with this?",
    "My partner’s food tastes has changed. What to do?",
  ],
}

export const Context = createContext(defaultContext)
export const useAuth = () => useContext(Context)

const ProviderWrapper = ({ children }) => {
  const [query, setQuery] = useState("")
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const suggestions = [
    "My partner does not want to get into the shower. What can I do?",
    "I don’t get any help with household tasks. How to deal with this?",
    "My partner’s food tastes has changed. What to do?",
  ]

  const addToFavourites = async id => {
    const docRef = doc(db, "users", user.uid)
    const docSnap = await getDoc(docRef)
    const favRef = doc(db, "favourites", id)
    const favSnap = await getDoc(favRef)

    if (docSnap.exists()) {
      let userDoc = { ...docSnap.data() }

      let savedContent = userDoc.saved
      let adding = false

      if (savedContent.includes(id)) {
        savedContent.splice(savedContent.indexOf(id), 1)
      } else {
        savedContent.push(id)
        adding = true
      }

      try {
        await setDoc(docRef, userDoc, { merge: true })
        setUser(userDoc)

        let favDoc = { ...favSnap.data() }

        if (adding) {
          favDoc[id] = favDoc[id] ? favDoc[id] + 1 : 1
        } else {
          favDoc[id] = favDoc[id] - 1
        }

        await setDoc(favRef, favDoc, { merge: true })

        return true
      } catch (e) {
        console.error("Error adding document: ", e)
        return false
      }
    }
  }

  const FoundUseful = async id => {
    const favRef = doc(db, "useful", id)
    const favSnap = await getDoc(favRef)

    try {
      let favDoc = { ...favSnap.data() }

      favDoc[id] = favDoc[id] ? favDoc[id] + 1 : 1

      await setDoc(favRef, favDoc, { merge: true })

      let usefulArray =
        JSON.parse(localStorage.getItem("dedicate_useful")) || []

      usefulArray.push(id)

      localStorage.setItem("dedicate_useful", JSON.stringify(usefulArray))

      return true
    } catch (e) {
      console.log("errorrr", e)
      console.error("Error adding document: ", e)
      return false
    }
  }

  const getData = async userID => {
    const docRef = doc(db, "users", userID)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      let data = docSnap.data()

      setUser(data)
      setLoading(false)
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (!user) {
      onAuthStateChanged(auth, userDB => {
        if (userDB) {
          getData(userDB.uid)
        } else {
          setLoading(false)
        }
      })
    } else {
      setLoading(false)
    }
  }, [user])

  const GoogleEvent = event => {
    logEvent(analytics, event.type, event.info)

    // logEvent(analytics, event.type, {
    //   location: "home",
    //   button: "category",
    //   page_title: event.title,
    //   page_slug: page.slug,
    // })
  }

  return (
    <Context.Provider
      value={{
        user,
        setUser,
        getData,
        loading,
        addToFavourites,
        FoundUseful,
        suggestions,
        setQuery,
        query,
        GoogleEvent,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export function useAuthValue() {
  return useContext(Context)
}

export default ProviderWrapper
