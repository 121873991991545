import { extendTheme } from "@chakra-ui/react"

const theme = {
  breakpoints: {
    base: "0em", // 0px
    sm: "48em", // ~768px
    md: "64em", // ~1024px
    lg: "73.75em", // ~1280px
    xl: "96em", // ~1536px
  },
  styles: {
    global: {
      body: {
        color: "brand.coal.950",
        fontFamily: `"Grotesk Text", system-ui, sans-serif`,
      },
    },
  },
  colors: {
    brand: {
      aubergine: "#74334A",
      coal: {
        50: "#F6F6F6",
        100: "#E7E7E7",
        200: "#D1D1D1",
        300: "#B0B0B0",
        400: "#888888",
        500: "#6D6D6D",
        600: "#5D5D5D",
        700: "#4F4F4F",
        800: "#454545",
        900: "#3D3D3D",
        950: "#222222",
      },
      citrus: {
        50: "#FFF6ED",
        100: "#FFEBD4",
        200: "#FFD3A8",
        300: "#FFB371",
        400: "#FF9249",
        500: "#FE6611",
        600: "#EF4C07",
        700: "#C63608",
        800: "#9D2C0F",
        900: "#7E2710",
        950: "#441006",
      },
      earth: "#88675A",
      rose: {
        50: "#FFF1F3",
        100: "#FEE5E9",
        200: "#FDCED8",
        300: "#FAA0B2",
        400: "#F77592",
        500: "#EF446F",
        600: "#DB2359",
        700: "#B9174B",
        800: "#9B1645",
        900: "#851640",
        950: "#4A071F",
      },
      ochre: {
        50: "#FEF9EE",
        100: "#FCF2D8",
        200: "#F8E2B0",
        300: "#F4CB7D",
        400: "#F0B45B",
        500: "#EA9325",
        600: "#DB791B",
        700: "#B65D18",
        800: "#914A1B",
        900: "#753E19",
        950: "#3F1E0B",
      },
      neptune: {
        50: "#F3F8F8",
        100: "#E0ECED",
        200: "#C5DBDC",
        300: "#9DC1C3",
        400: "#82ADAF",
        500: "#538587",
        600: "#476E73",
        700: "#3E5B60",
        800: "#394D51",
        900: "#334346",
        950: "#1E2A2E",
      },
      mint: {
        50: "#F5F8ED",
        100: "#E7F0D7",
        200: "#D5E5BB",
        300: "#B3CF87",
        400: "#95BA61",
        500: "#789F43",
        600: "#5C7E32",
        700: "#47612A",
        800: "#3C4E26",
        900: "#344324",
        950: "#19240F",
      },
      midnight: "#2F3E54",
      sunset: "#FF4236",
    },
  },
  components: {
    Button: {
      baseStyle: {
        rounded: "full",
        fontWeight: 700,
      },
      defaultProps: {
        size: "lg", // Set the default size to 'lg' (large)
      },
    },
    Heading: {
      variants: {
        heldane: {
          fontWeight: 400,
          fontFamily: `"Heldane Text", georgia, serif`,
        },
      },
    },
  },
  fonts: {
    heading: `"Grotesk Text", system-ui, sans-serif`,
    body: `"Heldane Text", georgia, serif`,
    heldane: `"Heldane Text", georgia, serif`,
  },
}

export default extendTheme(theme)

export const baseTheme = {}
